import React from 'react';

import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';

const Contact = () => {
  return (
    <Layout title="Contact">
      <article>
        <header>
          <PageHeading>Contact</PageHeading>
        </header>
        <div className="content">
          <p>
            You can find me on Bluesky as{' '}
            <a href="https://bsky.app/profile/angelos.social">@angelos.social</a>
          </p>
          <hr className="mt-5" />
          <p>You can also use this form to contact me:</p>
          <form
            name="contact"
            method="post"
            netlify-honeypot="phone"
            data-netlify="true"
            className="mt-5"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                Your name <span className="text-red-600">*</span>{' '}
                <input
                  type="text"
                  name="name"
                  required
                  className="mt-1 px-2 py-1 w-3/4 sm:w-1/2 block border border-gray-400 rounded"
                />
              </label>
            </p>
            <p className="mt-4">
              <label>
                Your email <span className="text-red-600">*</span>{' '}
                <input
                  type="email"
                  name="email"
                  required
                  className="mt-1 px-2 py-1 w-3/4 sm:w-1/2 block border border-gray-400 rounded"
                />
              </label>
            </p>
            <p className="hidden">
              <label>
                Your phone <input type="text" name="phone" />
              </label>
            </p>
            <p className="mt-4">
              <label>
                Your message <span className="text-red-600">*</span>{' '}
                <textarea
                  name="message"
                  required
                  className="mt-1 px-2 py-1 w-full sm:w-3/4 h-32 block border border-gray-400 rounded"
                />
              </label>
            </p>
            <p className="mt-4">
              <button
                type="submit"
                className="px-3 py-1 bg-indigo-500 hover:bg-indigo-600 text-white rounded cursor-pointer"
              >
                Send
              </button>
            </p>
          </form>
        </div>
      </article>
    </Layout>
  );
};

export default Contact;
