import React from 'react';
import PropTypes from 'prop-types';

import PubDate from '../components/PubDate';

const PostMeta = props => {
  const { post } = props;

  return (
    <p className="mt-1 text-gray-600">
      <PubDate date={post.frontmatter.date} />
      {post.frontmatter.language === 'el' && (
        <span className="ml-2" title="This post is written in Greek">🇬🇷</span>
      )}
    </p>
  );
};

PostMeta.propTypes = {
  post: PropTypes.shape({
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      language: PropTypes.string
    }).isRequired
  }).isRequired
};

export default PostMeta;
