import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import PostMeta from '../components/PostMeta';

const PostsList = props => {
  const { edges } = props;

  return (
    <ol>
      {edges.map(({ node }) => (
        <li key={node.id} className="mt-8 lg:flex">
          <Link
            to={node.fields.slug}
            className="text-2xl leading-tight lg:mr-3"
          >
            {node.frontmatter.title}
          </Link>
          <PostMeta post={node} />
        </li>
      ))}
    </ol>
  );
};

PostsList.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
          language: PropTypes.string
        }).isRequired,
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

export default PostsList;
