import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import PostsList from '../components/PostsList';

const Index = props => {
  const {
    data: {
      allMarkdownRemark: { edges }
    }
  } = props;

  return (
    <Layout>
      <PostsList edges={edges.slice(0, 10)} />
      {edges.length > 10 && (
        <p className="mt-10 text-lg">
          <Link to="/posts/">All posts &rarr;</Link>
        </p>
      )}
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              language: PropTypes.string
            }).isRequired,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            }).isRequired
          }).isRequired
        })
      )
    }).isRequired
  }).isRequired
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        fields: { listed: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 11
    ) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date
            language
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Index;
